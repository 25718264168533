import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1>プライバシーポリシー</h1>
    <section className="py-2">
      <h2>情報収集モジュール</h2>
      <p>
        Tastesoftで配信するアプリでは、広告配信を目的として以下の広告配信事業者がご利用者の情報を自動取得する場合がございます。この情報から個人が特定されることはありません。取得する情報、利用目的、第三者への提供等につきましては、以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。
        <br />
        <a href="https://policies.google.com/technologies/ads">
          AdMob（Google Inc.）
        </a>
      </p>
    </section>
  </Layout>
)

export default PrivacyPolicy
